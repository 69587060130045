import * as firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/database'
export default {
  state: {
    show: true,
    loading: false,
    error: null,
    temporaryPictureUrl: null,
    articles: [
      {
        imageSrc:
          'https://firebasestorage.googleapis.com/v0/b/first-project-6daea.appspot.com/o/blog%2Fart_500.jpg?alt=media&token=70ecef46-7883-4b54-a811-7755701a8b2c',
        id: 'text',
        shortDescription: ''
      },
      {
        imageSrc:
          'https://firebasestorage.googleapis.com/v0/b/first-project-6daea.appspot.com/o/blog%2Flibido_500.jpg?alt=media&token=f9fbaa45-8aa5-4313-862a-79f842480348',
        id: 'libido',
        shortDescription: ''
      },
      {
        imageSrc:
          'https://firebasestorage.googleapis.com/v0/b/first-project-6daea.appspot.com/o/blog%2Fimage-24-06-21-09-56.jpeg?alt=media&token=3a2424dc-72e6-49ab-ba55-4a0799b2d12d',
        id: 'seven',
        shortDescription: ''
      },
      {
        imageSrc:
          'https://firebasestorage.googleapis.com/v0/b/first-project-6daea.appspot.com/o/blog%2Fnejro_500.jpg?alt=media&token=e265aadf-de06-49cd-95ba-84a919aa25a5',
        id: 'neurographics',
        shortDescription: ''
      },
      {
        imageSrc:
          'https://firebasestorage.googleapis.com/v0/b/first-project-6daea.appspot.com/o/blog%2FPsychosomatics%2FIMG_1795.jpg?alt=media&token=03a21351-2baa-43d5-80f8-15f427a2ddf8',
        id: 'psychosomatics',
        shortDescription: ''
      },
      {
        imageSrc:
          'https://firebasestorage.googleapis.com/v0/b/first-project-6daea.appspot.com/o/blog%2FLungs%2Flegkie_500.jpg?alt=media&token=d1b1be1c-9c53-4c09-b1e3-7ad5edd06c16',
        id: 'lungs',
        shortDescription: ''
      }
    ],
    promoCodes: [],
    serviceWorker: null,
    stream: null,
    listAuthors: [],
    selectedAuthor: {
      id: 'all',
      name: 'Все'
    },
    freeEvent: null,
    eventParticipants: [],
    listRubrics: [
      { id: 'all', name: 'Все' },
      { name: 'Финансы', id: 'finance' },
      { name: 'Самореализация', id: 'realization' },
      { name: 'Отношения', id: 'relationship' },
      { name: 'Здоровье', id: 'health' }
    ],
    selectedRubric: {
      id: 'all',
      name: 'Все'
    }
  },
  getters: {
    loading: state => state.loading,
    error: state => state.error,
    show: state => state.show,
    listAuthors: state => state.listAuthors,
    filterListAuthors: state => state.listAuthors.filter(author => !author.draft),
    author: state => id => state.listAuthors.find(author => author.id === id),
    userAuthor: (state, getters) =>
      getters.filterListAuthors.find(author => author.email === getters.user.email)
  },
  mutations: {
    dataMessage: (state, data) => (state.dataMessage = data),
    setLoading: (state, payload) => (state.loading = payload),
    setError: (state, payload) => (state.error = payload),
    clearError: state => (state.error = null),
    changeTemporaryPictureUrl: (state, payload) => (state.temporaryPictureUrl = payload),
    changeShow: (state, payload) => (state.show = payload),
    updatedServiceWorker: (state, payload) => (state.serviceWorker = payload),
    getPromoCodes: (state, payload) => (state.promoCodes = payload),
    getMedia: (state, stream) => (state.stream = stream),
    getAuthors: (state, listAuthors) => (state.listAuthors = listAuthors),
    selectAuthor: (state, dataAuthor) => (state.selectedAuthor = dataAuthor),
    getEvent: (state, event) => (state.freeEvent = event),
    updateEventParticipants: (state, userData) => state.eventParticipants.push(userData),
    getEventParticipants: (state, participantsList) =>
      (state.eventParticipants = participantsList || []),
    selectRubric: (state, data) => (state.selectedRubric = data)
  },
  actions: {
    changeShow: ({ commit }) => {
      commit('changeShow', false)
      setTimeout(() => commit('changeShow', true), 1000)
    },
    async getPromoCodes({ commit }) {
      commit('setLoading', true)
      commit('clearError')
      try {
        const snapshot = await firebase
          .firestore()
          .collection('PromoCodes')
          .get()
        let promoCodes = []
        snapshot.forEach(doc => promoCodes.push(doc.data()))
        commit('getPromoCodes', promoCodes)
        commit('setLoading', false)
      } catch (error) {
        commit('setLoading', false)
        commit('setError', error.message)
        throw error
      }
    },
    async getMedia({ commit }, constraints) {
      commit('clearError')
      try {
        const stream = await navigator.mediaDevices.getUserMedia(constraints)
        commit('getMedia', stream)
      } catch (error) {
        commit('setError', error.message)
      }
    },
    async getListAuthors({ commit }) {
      commit('clearError')
      commit('setLoading', true)
      try {
        const querySnapshot = await firebase
          .firestore()
          .collection('Authors')
          .get()
        let authorList = []
        querySnapshot.forEach(elem => authorList.push(elem.data()))
        commit('getAuthors', authorList.reverse())
        commit('setLoading', false)
      } catch (error) {
        commit('setLoading', false)
        commit('setError', error.message)
      }
    },
    async getEvent({ commit }, { eventId }) {
      commit('clearError')
      commit('setLoading', true)
      try {
        const event = await firebase
          .firestore()
          .collection('EventFree')
          .doc(eventId)
          .get()
        commit('getEvent', event.data())
        commit('setLoading', false)
      } catch (error) {
        commit('setLoading', false)
        commit('setError', error.message)
      }
    },
    async getEventParticipants({ commit }, { eventId }) {
      commit('clearError')
      commit('setLoading', true)
      try {
        const snapshot = await firebase
          .database()
          .ref(`EventParticipants/${eventId}`)
          .once('value')

        commit('getEventParticipants', Object.values(snapshot.val()))
        commit('setLoading', false)
      } catch (error) {
        commit('setLoading', false)
        commit('setError', error.message)
      }
    },
    async saveEventParticipant({ commit }, { firstName, lastName, email, eventId }) {
      commit('setLoading', true)
      commit('clearError')
      try {
        await firebase
          .database()
          .ref(`EventParticipants/${eventId}/${email.split('.').join('_')}`)
          .set({ firstName, lastName, email })
        commit('updateEventParticipants', { firstName, lastName, email })
        commit('setLoading', false)
      } catch (error) {
        commit('setLoading', false)
        commit('setError', error.message)
        throw error
      }
    }
  }
}
