import * as firebase from 'firebase/app'
import 'firebase/database'
import 'firebase/storage'
import axios from 'axios'
export default {
  state: {
    token: localStorage.getItem('_ken'),
    url: 'https://metamorfosi.herokuapp.com'
    // url: 'http://localhost:3000'
    // url: 'https://test-metamorfosi.herokuapp.com'
    //listUsersFilter: []
  },
  getters: {
    token: state => state.token,
    isLoggedIn: state => !!state.token,
    url: state => state.url
  },
  mutations: {
    setToken: (state, token) => (state.token = token),
    getIdToken: (state, payload) => (state.idToken = payload)
  },

  actions: {
    async getIdToken({ commit, getters }, user) {
      // запрос на принадлежность к admin и клубу.
      if (user) {
        commit('setLoading', true)
        commit('clearError')
        try {
          const idToken = await firebase.auth().currentUser.getIdToken(true)
          const response = await axios.get(`${getters.url}/metamorfosi/verifyIdToken`, {
            headers: { Authorization: `Bearer ${idToken}` }
          })
          if (response.data.admin) {
            commit('setAdmin', response.data.uid)
          }
          commit('setToken', idToken)
          localStorage.setItem('_ken', '1')
          commit('setLoading', false)
        } catch (error) {
          commit('setLoading', false)
          commit('setError', error.message)
          throw error
        }
      }
    }
  }
}
