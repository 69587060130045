export default {
  apiKey: 'AIzaSyDI9RkiZj1IlL9oK4gkLL4gGSi3SO5V-IM',
  authDomain: 'first-project-6daea.firebaseapp.com',
  databaseURL: 'https://first-project-6daea.firebaseio.com',
  projectId: 'first-project-6daea',
  storageBucket: 'first-project-6daea.appspot.com',
  messagingSenderId: '508842986473',
  appId: '1:508842986473:web:ebc24fa55cdfd44456d978',
  measurementId: 'G-ZB79PW446N'
}
