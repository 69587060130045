import * as firebase from 'firebase/app'
import 'firebase/firestore'

export default {
  state: {
    galleryList: []
  },
  getters: {
    galleryListFilterLanguage: state => i18n => {
      if (i18n === 'en') {
        return state.galleryList.map(gallery => {
          return {
            draft: gallery.draft,
            galleryTitle: gallery.galleryTitle,
            galleryName: gallery.galleryNameEn,
            shortDescription: gallery.shortDescriptionEn,
            galleryDescription: gallery.galleryDescriptionEn,
            previewTextLink: gallery.previewTextLinkEn || '',
            previewLink: gallery.previewLink || '',
            galleryAudioSrc: gallery.galleryAudioSrc,
            imageSrc: gallery.imageSrc,
            descriptionSrc: gallery.descriptionSrc,
            imageBlockSrc: gallery.imageBlockSrc
          }
        })
      } else {
        return state.galleryList.map(gallery => {
          return {
            draft: gallery.draft,
            galleryTitle: gallery.galleryTitle,
            galleryName: gallery.galleryNameRu,
            shortDescription: gallery.shortDescriptionRu,
            galleryDescription: gallery.galleryDescriptionRu,
            previewTextLink: gallery.previewTextLinkRu || '',
            previewLink: gallery.previewLink || '',
            galleryAudioSrc: gallery.galleryAudioSrc,
            imageSrc: gallery.imageSrc,
            descriptionSrc: gallery.descriptionSrc,
            imageBlockSrc: gallery.imageBlockSrc
          }
        })
      }
    }
  },
  mutations: {
    getGalleryList: (state, galleryList) => {
      state.galleryList = galleryList.sort((a, b) => {
        if (+a.galleryIndex < +b.galleryIndex) return 1
        if (+a.galleryIndex === +b.galleryIndex) return 0
        if (+a.galleryIndex > +b.galleryIndex) return -1
      })
    }
  },
  actions: {
    async getGalleryList({ commit }) {
      commit('clearError')
      commit('setLoading', true)
      try {
        const querySnapshot = await firebase
          .firestore()
          .collection('gallery')
          .get()
        let galleryList = []
        querySnapshot.forEach(doc => galleryList.push(doc.data()))
        commit('getGalleryList', galleryList)
        commit('setLoading', false)
      } catch (error) {
        commit('setLoading', false)
        commit('setLoading', error.message)
        throw error
      }
    }
  }
}
