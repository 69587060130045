import Vue from 'vue'
import Router from 'vue-router'
import store from './store'
import * as firebase from 'firebase/app'

import studiosRout from './router/studiosRout'
import galleryRout from './router/gallery-authors-rout'
import calendarRout from './router/calendarRout'
import blogRout from './router/blogRout'
import miniGameRout from './router/miniGameRout'
import artTherapy from './router/artTherapy'

function verification(params) {
  return (
    (store.getters.user.payments && store.getters.user.payments[params]) ||
    (store.getters.userAuthor && store.getters.userAuthor.products.includes(params))
  )
}

Vue.use(Router)
const router = new Router({
  mode: 'history',
  routes: [
    ...studiosRout,
    ...galleryRout,
    ...calendarRout,
    ...blogRout,
    ...miniGameRout,
    ...artTherapy,
    {
      path: '/',
      name: 'Home',
      component: () => import('./views/Home')
    },
    {
      path: '/aboutUs',
      name: 'AboutUs',
      component: () => import('./views/AboutUs')
    },
    {
      path: '/login',
      name: 'Login',
      component: () => import('./components/Auth/Login')
    },
    {
      path: '/registration',
      name: 'Registration',
      component: () => import('./components/Auth/Registration')
    },
    {
      path: '/userProfile',
      name: 'UserProfile',
      meta: {
        requiresAuth: true
      },
      component: () => import('./views/UserProfile')
    },
    {
      path: '/registerFreeEvent/:eventId',
      name: 'RegisterFreeEvent',
      props: true,
      component: () => import('./views/RegisterFreeEvent')
    },
    {
      path: '/engineeringWorks/:id',
      name: 'EngineeringWorks',
      props: true,
      component: () => import('./views/EngineeringWorks')
    },
    {
      path: '/thankYoy/:id',
      name: 'ThankYou',
      props: true,
      component: () => import('./views/ThankYou')
    },
    {
      path: '/especiallyForFacebook',
      name: 'EspeciallyForFacebook',
      component: () => import('./views/EspeciallyForFacebook')
    },
    {
      path: '*',
      name: 'ErrorCmp',
      component: () => import('./components/shared/ErrorCmp')
    }
  ],
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return { selector: to.hash }
    }
    if (savedPosition) {
      return savedPosition
    } else if (!to.matched.some(record => record.meta.notScroll)) {
      return { x: 0, y: 0 }
    }
  }
})
router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!store.getters.isLoggedIn) next('/login?loginError=true')
    next()
  } else {
    next()
  }
})
router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.userAdmin)) {
    if (store.getters.admin) {
      next()
    } else {
      firebase.auth().onAuthStateChanged(user => {
        store.dispatch('getIdToken', user).then(() => {
          if (store.getters.admin) {
            next()
          } else {
            next('/*')
          }
        })
      })
    }
  } else {
    next()
  }
})
router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresPayment)) {
    if (verification(to.params.titleStudios || to.params.titleCalendar)) {
      next()
    } else {
      firebase.auth().onAuthStateChanged(user => {
        if (user) {
          store
            .dispatch('autoDataUser', { uid: user.uid })
            .then(() => store.dispatch('getListAuthors'))
            .then(() => {
              if (verification(to.params.titleStudios || to.params.titleCalendar)) {
                next()
              } else {
                next('/?paymentError=true')
              }
            })
        }
      })
    }
  } else {
    next()
  }
})
router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.draft)) {
    if (to.params.titleStudios) {
      store.dispatch('getListStudios', 'studios').then(() => {
        let dataStudio = store.getters.dataStudio(to.params.titleStudios)
        if (dataStudio.draft) {
          next('/studios')
        } else {
          next()
        }
      })
    } else {
      store.dispatch('getListStudios', 'calendars').then(() => {
        let dataStudio = store.getters.dataCalendar(to.params.titleCalendar)
        if (dataStudio.draft) {
          next('/calendars')
        } else {
          next()
        }
      })
    }
  } else {
    next()
  }
})

export default router
