export default [
  {
    path: '/galleryList',
    name: 'galleryList',
    component: () => import('../views/Gallery/GalleryList')
  },
  {
    path: '/galleryCustomers/:title',
    name: 'galleryCustomers',
    props: true,
    component: () => import('../views/Gallery/GalleryCustomers')
  },
  {
    path: '/authorsList',
    name: 'AuthorsList',
    component: () => import('../views/authors/AuthorsList')
  },
  {
    path: '/author/:id',
    name: 'Author',
    props: true,
    component: () => import('../views/authors/Author')
  }
]
