import axios from 'axios'
import stripe from '../../src/pk_live'

export default {
  state: {},
  getters: {},
  mutations: {},
  actions: {
    async payStripe({ commit, getters }, paymentDetails) {
      commit('clearError')
      try {
        const response = await axios.post(
          `${getters.url}/metamorfosi/payStripe`,
          { ...paymentDetails },
          { headers: { Authorization: `Bearer ${getters.token}` } }
        )
        stripe.redirectToCheckout({
          sessionId: response.data
        })
      } catch (error) {
        commit('setError', error.message)
        throw error
      }
    },
    async portalSessionStripe({ commit, getters }) {
      commit('clearError')
      try {
        const response = await axios.get(`${getters.url}/metamorfosi/portalSessionStripe`, {
          headers: { Authorization: `Bearer ${getters.token}` }
        })
        window.location.href = response.data
      } catch (error) {
        commit('setError', error.message)
        throw error
      }
    },
    async subscriptionDeleteStripe({ commit, getters }, listSubscriptions) {
      commit('clearError')
      try {
        await axios.post(
          `${getters.url}/metamorfosi/stripeSubscriptionDelete`,
          { listSubscriptions },
          { headers: { Authorization: `Bearer ${getters.token}` } }
        )
      } catch (error) {
        commit('setError', error.message)
        throw error
      }
    }
  }
}
